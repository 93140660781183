/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/theming";

@import "styles/variables.scss";
@import "styles/fonts.scss";

$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Source Sans Pro"
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$agranet-front-portals-companies-primary: mat.define-palette($agranet-primary);
$agranet-front-portals-companies-accent: mat.define-palette($agranet-accent);
$agranet-front-portals-companies-warn: mat.define-palette(mat.$red-palette);

$agranet-front-portals-companies-theme: mat.define-light-theme(
  $agranet-front-portals-companies-primary,
  $agranet-front-portals-companies-accent,
  $agranet-front-portals-companies-warn
);

@include mat.all-legacy-component-themes($agranet-front-portals-companies-theme);

html {
  scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Source Sans Pro", "Arial", sans-serif;
  background: $background;
}
