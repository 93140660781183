/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 300;
  src: local("Libre Franklin Light Italic"), local("LibreFranklin-LightItalic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizGREVItHgc8qDIbSTKq4XkRiUa454xm1fpjeOg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 300;
  src: local("Libre Franklin Light Italic"), local("LibreFranklin-LightItalic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizGREVItHgc8qDIbSTKq4XkRiUa454xm1npjQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 400;
  src: local("Libre Franklin Italic"), local("LibreFranklin-Italic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizBREVItHgc8qDIbSTKq4XkRiUa6zsTjmbI.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 400;
  src: local("Libre Franklin Italic"), local("LibreFranklin-Italic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizBREVItHgc8qDIbSTKq4XkRiUa6zUTjg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 600;
  src: local("Libre Franklin SemiBold Italic"), local("LibreFranklin-SemiBoldItalic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizGREVItHgc8qDIbSTKq4XkRiUa4-o3m1fpjeOg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 600;
  src: local("Libre Franklin SemiBold Italic"), local("LibreFranklin-SemiBoldItalic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizGREVItHgc8qDIbSTKq4XkRiUa4-o3m1npjQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 800;
  src: local("Libre Franklin ExtraBold Italic"), local("LibreFranklin-ExtraBoldItalic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizGREVItHgc8qDIbSTKq4XkRiUa45I1m1fpjeOg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 800;
  src: local("Libre Franklin ExtraBold Italic"), local("LibreFranklin-ExtraBoldItalic"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizGREVItHgc8qDIbSTKq4XkRiUa45I1m1npjQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 300;
  src: local("Libre Franklin Light"), local("LibreFranklin-Light"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizAREVItHgc8qDIbSTKq4XkRi20-SI6q1vxiQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 300;
  src: local("Libre Franklin Light"), local("LibreFranklin-Light"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizAREVItHgc8qDIbSTKq4XkRi20-SI0q1s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  src: local("Libre Franklin"), local("LibreFranklin-Regular"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizDREVItHgc8qDIbSTKq4XkRiUR2zcLig.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  src: local("Libre Franklin"), local("LibreFranklin-Regular"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizDREVItHgc8qDIbSTKq4XkRiUf2zc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  src: local("Libre Franklin SemiBold"), local("LibreFranklin-SemiBold"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizAREVItHgc8qDIbSTKq4XkRi3A_yI6q1vxiQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  src: local("Libre Franklin SemiBold"), local("LibreFranklin-SemiBold"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizAREVItHgc8qDIbSTKq4XkRi3A_yI0q1s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 800;
  src: local("Libre Franklin ExtraBold"), local("LibreFranklin-ExtraBold"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizAREVItHgc8qDIbSTKq4XkRi24_SI6q1vxiQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 800;
  src: local("Libre Franklin ExtraBold"), local("LibreFranklin-ExtraBold"),
    url(https://fonts.gstatic.com/s/librefranklin/v3/jizAREVItHgc8qDIbSTKq4XkRi24_SI0q1s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Source-sans-pro */

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-ExtraLight.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-ExtraLight.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-ExtraLight.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-ExtraLightIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-ExtraLightIt.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-ExtraLightIt.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-ExtraLightIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Light.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Light.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-Light.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-LightIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-LightIt.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-LightIt.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-LightIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Regular.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Regular.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-Regular.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-It.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-It.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-It.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-It.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Semibold.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Semibold.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-Semibold.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-SemiboldIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-SemiboldIt.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-SemiboldIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Bold.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Bold.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-Bold.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-BoldIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-BoldIt.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-BoldIt.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-BoldIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-Black.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-Black.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-Black.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/SourceSansPro/WOFF2/TTF/SourceSansPro-BlackIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/SourceSansPro/WOFF/OTF/SourceSansPro-BlackIt.otf.woff") format("woff"),
    url("/assets/fonts/SourceSansPro/OTF/SourceSansPro-BlackIt.otf") format("opentype"),
    url("/assets/fonts/SourceSansPro/TTF/SourceSansPro-BlackIt.ttf") format("truetype");
}
