$agranet-primary: (
  50: #ededed,
  100: #d2d2d2,
  200: #b5b5b4,
  300: #979796,
  400: #808080,
  500: #6a6a69,
  600: #626261,
  700: #575756,
  800: #4d4d4c,
  900: #3c3c3b,
  A100: #f5f5a0,
  A200: #f0f072,
  A400: #ffff30,
  A700: #ffff17,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$agranet-accent: (
  50: #fce3d8,
  100: #fad2c1,
  200: #f7b497,
  300: #f4956d,
  400: #f17f4e,
  500: #ffffff,
  600: #ef6a2f,
  700: #bf5930,
  800: #a0554c,
  900: #795151,
  A100: #ffffff,
  A200: #ffe4df,
  A400: #ffb9ac,
  A700: #ffa392,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$alabaster: #f8f8f8;
$dark-gray: #222;
$green: #45c25e;
$red: red;
$white: white;
$orange: map-get($agranet-accent, 400);
$light-gray: map-get($agranet-primary, 200);
$companyColor: #3498db;

$agrimaker: #f06a2f;
$fast: #f06a2f;
$agata: #3096a1;
$ferme-directe: #e9592e;
$othercompanies: #6b6b6b;
$notif-accent: #ef6a2f;
$notif-primary: #2f70aa;
