@import "colors";

$font: map-get($agranet-primary, 400);
$menu: $dark-gray;
$nav-hover: map-get($agranet-accent, 600);
$highlight: map-get($agranet-accent, 600);
$background: $alabaster;
$font: map-get($agranet-primary, 600);
$title-font: map-get($agranet-accent, 600);
$highlight2: map-get($agranet-accent, 100);
$companyColor: $companyColor;


mat-checkbox.mat-checkbox {
  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $notif-accent;
    }
  }
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $notif-accent;
    }
  }
}

mat-radio-group {
  mat-radio-button {
      .mat-radio-outer-circle {
        border-color: $notif-accent;
      }
      &.mat-radio-button.mat-accent.mat-radio-checked {
        .mat-radio-inner-circle {
          background-color: $notif-accent;
        }
        .mat-radio-outer-circle {
          border-color: $notif-accent;
        }
      }
  }
}
